import styled from 'styled-components'
import { Box, Flex } from 'components/Box'
import { Col } from 'react-bootstrap'
import { FiMail } from 'react-icons/fi'


export const PoweredBy = styled(Flex)`
margin-top:18px;
margin-bottom:10px;
  img {
    margin-left:20px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      display:flex;
      flex-direction: column;
      img:first-child {
        width: 80px;
        margin-bottom:15px;
      }
    }
  }
`

export const StyledContainerBannner = styled(Box)`
  width: 100%;
  display:flex;
  min-height: calc(100vh - 100px);
  .text-right {
    text-align: right;
  }
  .sub-title {
    font-size: 46px;
  }
  .align-left {
    align-items: flex-start;
  }
  input[type='email'] {
    min-width: 190px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;

    .sub-title {
      font-size: 36px;
    }
    .text-right {
      text-align: center;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
     margin-bottom: 80px;
    .sub-title {
      font-size: 26px;
    }
  }
`

export const StyledContainer = styled(Box)`
  display:flex;
  height: 770px;
  background-image: url('../assets/images/FaqBG.png');
  background-size: 100%;
  background-repeat: no-repeat;
  &.full-width {
    width: 100%;
  }
  .sub-title {
    font-size: 46px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    .sub-title {
      font-size: 36px;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 170px;
    height: 950px;
    .sub-title {
      font-size: 26px;
    }
  }
`

export const StyledBackground = styled(Box)`
  background-image: url('../assets/images/MainScreenBg.png');
  background-size: 100%;
  background-repeat: no-repeat;
   background-position-y: -130px;
  ${({ theme }) => theme.mediaQueries.sm} { 
    background-position-y: 10%;
    background-position-x: center;
    background-size: 1000px;
  }
`


export const NotifyContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 4px;
  margin-top: 40px;
  padding: 0 5px 0 14px;
  width: 100%;
  max-width: 420px;
  &.no-bg {
    background:none;
    justify-content:flex-start;
    padding-left:0;
    .main-btn {
      width: 164px;
      height: 44px;
      background: linear-gradient(133.82deg, #513CFF 13.2%, #23ABD4 100%);
      border-radius: 8px;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      padding:11px;
      text-align: center;
      color: #FFFFFF;
      margin-right:25px;
      ${({ theme }) => theme.mediaQueries.sm} {
        width: calc(50% - 5px);
        margin-right:10px;
        padding:11px 0;
      }
    }
    .second-btn {
      width: 164px;
      height: 44px;
      border-radius: 8px;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      padding:11px;
      position: relative;
      text-align: center;
      color: #FFFFFF;
      ${({ theme }) => theme.mediaQueries.sm} {
        width: calc(50% - 5px);
      }
      &:before {
        content: '';
        border: 1px solid transparent;
        background: linear-gradient(133.82deg,#513cff 13.2%,#23abd4 100%) border-box;
        -webkit-mask: linear-gradient(rgb(255,255,255) 0px,rgb(255,255,255) 0px) padding-box padding-box, linear-gradient(rgb(255,255,255) 0px,rgb(255,255,255) 0px);
        -webkit-mask-composite: xor;
        -webkit-mask-composite: exclude;
        width: 100%;
        position: absolute;
        white-space: nowrap;
        inset: 0px;
        border-radius: 8px;
        pointer-events: none;
         
      }
    }
  }
  background: ${({ theme }) => theme.colors.backgroundAlt};
  input {
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 12px;
    outline: none;
    width: 80%;
    ::placeholder {
      color: ${({ theme }) => theme.colors.hover};
      font-size: 12px;
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.light};
    }
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.backgroundAlt} inset;
    transition: background-color 5000s ease-in-out 0s;
    background: none !important;
  }
  input:-internal-autofill-selected {
    border: none;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.backgroundAlt} inset;
    transition: background-color 5000s ease-in-out 0s;
    background: none !important;
  }
  .notify-text {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
  }
  margin-bottom: 34px;
  form {
    display: flex;
    width: 100%;
  }
  button {
    cursor: pointer;
  }
  .hidden {
    display: none;
  }
`
export const ColView = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
`

export const MailIcon = styled(FiMail)`
  color: ${({ theme }) => theme.colors.textDisabled}50;
  font-size: 12px;
  margin-right: 5px;
`

export const SpanText = styled(Flex)`
.content-with-border {
  position:relative;
}
  .bottom-border {
    background: ${({ theme }) => theme.colors.buttonprimary};
    height: 4px;
    border-radius: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
${({ theme }) => theme.mediaQueries.sm} {
  flex-direction: column;
  align-items: flex-start;
}
`

export const TellMoreFlex = styled(Flex)`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.secondary};
  margin-top: 50px;
  .title {
    color: ${({ theme }) => theme.colors.textDisabled};
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.light};
  }
  .action {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.medium};
    cursor: pointer;
    width: fit-content;
    svg {
      &:hover {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
      background-image: ${({ theme }) => theme.colors.buttonprimary};
      /* Use the text as a mask for the background. */
      /* This will show the gradient as a text color rather than element bg. */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }
`

export const FooterLink = styled(Box)`
  color: ${({ theme }) => theme.colors.textDisabled};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.light};
  a {
    color: ${({ theme }) => theme.colors.textDisabled};
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.light};
    margin-left: 4px;
    margin-right: 4px;
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`

export const ChainBackground = styled(Col)`
  background-image: url('../assets/images/Line.png');
  background-repeat: no-repeat;
  height: 500px;
  padding-right: 0;
  background-position: right 70px top -70px;
  background-origin: content-box;
  background-size: auto 125%;

  @media (max-width: 520px) {
    background-size: 60px auto;
    padding-right: 0;
    background-position: right center;
    min-width:60px;
  }
`

export const BannerImage = styled(Box)`
  width: 100%;
  img {
    max-width: 450px;
  }
  video {
    max-width: 600px;
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    padding-left: 0px;
    margin-top: 80px;
  }
`

export const FAQquestioncard = styled(Flex)`
width: 813px;
padding: 15px 0px 15px;
border-radius: 8px 8px 0 0;
background: #0F1111;
box-shadow: 0px 20px 52px 0px rgba(0, 0, 0, 0.38);
${({ theme }) => theme.mediaQueries.md} {
  max-width:80%;
  width:80%;
}
`

export const FAQanswercard = styled(Flex)`
width: 813px;
height: 100%;
border-radius: 0 0 8px 8px;
background: #0F1111;
box-shadow: 0px 20px 52px 0px rgba(0, 0, 0, 0.38);
${({ theme }) => theme.mediaQueries.md} {
  max-width:80%;
  width:80%;
}
`