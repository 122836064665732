import styled from 'styled-components'
import { Text } from 'components/Text'
import { Box, Flex } from 'components/Box'
import { Col, Row } from 'react-bootstrap'

export const HeadingText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.4;
  font-size: 54px;
  max-width:900px;
  .bottom-border {
    display:inline-block;
    position:relative;
    &:before {
      content:'';
      background: ${({ theme }) => theme.colors.buttonprimary};
      height: 4px;
      border-radius: 80px;
      position: absolute;
      bottom: 6px;
      width: 100%;
      z-index: -1;
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 36px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 26px;
  }
`

export const FormWrapper = styled(Box)`
  margin-top: 40px;
  margin-bottom: 50px;
  .pointer-none {
    pointer-events: none !important;
    background:none;
  }
  .padding-right-50 {
    padding-right: 50px;

    input::-webkit-datetime-edit-day-field,
    input::-webkit-datetime-edit-fields-wrapper,
    input::-webkit-datetime-edit-hour-field,
    input::-webkit-datetime-edit-minute,
    input::-webkit-datetime-edit-month-field,
    input::-webkit-datetime-edit-text,
    input::-webkit-datetime-edit-year-field {
      font-weight: 300 !important;
    }
    input::-webkit-datetime-edit-text,
    input::-webkit-datetime-edit-month-field,
    input::-webkit-datetime-edit-day-field,
    input::-webkit-datetime-edit-year-field {
      color: #fff !important;
    }
    input::-webkit-datetime-edit-text,
    input::-webkit-datetime-edit-month-field,
    input::-webkit-datetime-edit-day-field,
    input::-webkit-datetime-edit-year-field {
      color: ${({ theme }) => theme.colors.hover} !important;
      font-size: 12px !important;
      font-family: Poppins !important;
      font-weight: 300 !important;
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 13px;
    .padding-right-50 {
      padding-right: 0px;
      padding-left: 0;
    }
  }
`
export const Input = styled.input`
  height: 48px;
  border-radius: 4px;
  padding: 0 8px;
  border :none;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border:2px solid transparent;
  &:nth-last-child(3) {
    border: 2px solid rgba(251,23,23,0.4);
  }
  &:focus {
    color: ${({ theme }) => theme.colors.text};
    outline:none;
  }
  &:focus:nth-last-child(2) ~ span {
    max-height:48px;
    border-radius: 4px; /*1*/
  border: 1px solid transparent; /*2*/
  background: linear-gradient(133.82deg, #513CFF 13.2%, #23ABD4 100%) border-box; /*3*/
  -webkit-mask: /*4*/
     linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  -webkit-mask-composite: exclude;
    mask-composite: exclude; /*5*/

    width: 100%;
    position: absolute;
    top: 42px;
    bottom: 0px;
    border-radius: 4px;
    pointer-events:none;
  }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
  }
  
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-year-field,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute-field,
  ::-webkit-datetime-edit-second-field,
  ::-webkit-datetime-edit-ampm-field {
    color: ${({ theme }) => theme.colors.hover};
    text-transform: uppercase;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.hover};
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.light};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.backgroundAlt} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`
export const InputWrapper = styled(Flex)`
  justify-content: space-between;
  flex-direction: row;
  .input-section {
    flex-direction: column;
    width: 48%;
    position: relative;
    label {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.light};
      color: ${({ theme }) => theme.colors.text};
      font-size: 12px;
      margin-bottom: 8px;
      margin-left: 0.6rem;
      margin-top: 1rem;
      text-transform: lowercase;
      span {
        text-transform: none;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // input[type="date" i]::-webkit-calendar-picker-indicator {
    //   display:none;
    // }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .showtrue {
      color: ${({ theme }) => theme.colors.failure};
      font-size: 10px;
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.light};
      margin: 0 0 0 0.2rem;
      text-transform: lowercase;
      line-height: 1;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    input {
      font-weight: 300;
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: column;
    .input-section {
      width: 100%;
      position: relative;
    }
  }
`

export const TextArea = styled.textarea`
  height: 100px;
  border: none;
  border-radius: 4px;
  padding: 15px 11px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border:2px solid transparent;
  &:nth-last-child(3) {
    border: 2px solid rgba(251,23,23,0.4);
  }
  &:focus {
    color: ${({ theme }) => theme.colors.text};
    outline:none;
  }
  &:focus:nth-last-child(2) ~ span {
    border-radius: 4px; /*1*/
  border: 1px solid transparent; /*2*/
  background: linear-gradient(160deg, #513CFF 13.2%, #23ABD4 100%) border-box; /*3*/
  -webkit-mask: /*4*/
     linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  -webkit-mask-composite: exclude;
          mask-composite: exclude; /*5*/
    width: 100%;
    position: absolute;
    top: 25px;
    bottom: 0px;
    border-radius: 4px;
    pointer-events:none;
  }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.hover};
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.light};
  }
`

export const TextAreaWrapper = styled(Flex)`
  margin-top: 15px;
  flex-direction: row;
  .textArea-section {
    flex-direction: column;
    position: relative;
    width: 100%;
    label {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.light};
      color: ${({ theme }) => theme.colors.text};
      font-size: 12px;
      margin-bottom: 8px;
      margin-left: 0.8rem;
    }
    .showtrue {
      color: ${({ theme }) => theme.colors.failure};
      font-size: 10px;
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ theme }) => theme.fonts.light};
      margin: 0 0 0 0.2rem;
      text-transform: lowercase;
      line-height: 1;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    textarea {
      font-weight: 300;
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: column;
  }
`

export const ActionBox = styled(Col)`
  height: fit-content;
  padding: 20px;
  border: 0.1px solid ${({ theme }) => theme.colors.textDisabled}50;
  border-radius: 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 250px;
  max-width: 354px;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaQueries.md} {
    // margin-left: 10px;
    max-width: none;
  }
`
export const StyledBackground = styled(Box)`
  right: 0px;
  top: 0px;
  left: 0px;
  z-index: 0;
  position:relative;
  margin-top: -121px;
  .inner-style {
    background-image: url('../assets/images/MainScreenBg2.png');
    background-size: 100% 67%;
    background-repeat: no-repeat;
    background-position-y: -50px;
    & > div {
      max-width: 1330px;
      padding-right: 30px;
    }
    .no-wrap {
      white-space: nowrap;
    }
  }
`

export const TabHeadingWrapper = styled(Flex) <{ tabId?: number; activeTab?: number }>`
  align-items: center;
  outline: none;
  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${({ theme, activeTab, tabId }) =>
    activeTab >= tabId ? theme.colors.buttonprimary : theme.colors.textDisabled};
    .tab-text {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: ${({ activeTab, tabId, theme }) => (activeTab === tabId ? '500' : theme.fonts.light)};
      color: ${({ theme }) => theme.colors.text};
      font-size: ${({ activeTab, tabId }) => (activeTab === tabId ? '16px' : '12px')};
      white-space: nowrap;
      // margin-left: -30px;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      margin-top:${({ activeTab, tabId }) => (activeTab === tabId ? '25px' : "28px")};
    }
  }
  .line {
    width: 200px;
    height: 2px;
    background: ${({ theme, activeTab, tabId }) =>
    tabId <= activeTab - 1 ? theme.colors.buttonprimary : theme.colors.textDisabled};
  }
  &:hover {
    outline: none;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 120px;
    &:last-child {
      width: auto;
    }
    .circle {
      .tab-text {
        font-size: 12px;
        margin-top: 25px;
      }
    }
    .line {
      width: 120px;
    }
  }
`
export const MainLabelText = styled(Text) <{ marginTop?: string }>`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '30px')};
  margin-bottom: 20px;
`
export const ChainWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 400px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
  }
`

export const Switch = styled(Flex) <{ isChecked?: boolean }>`
  background: ${({ theme, isChecked }) => (isChecked ? theme.colors.buttonprimary : 'rgba(120, 120, 128, 0.16)')};
  border-radius: 30px;
  height: 24.22px;
  width: 38.84px;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`

export const SwitchToggle = styled(Flex) <{ state: boolean }>`
  background: ${({ theme, state }) => (state ? theme.colors.text : 'transparent')};
  border-radius: 50%;
  width: 21px;
  height: 21px;
  align-items: center;
  justify-content: center;
`

export const ChianCol = styled(Col)`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
`
export const UploadText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: small;
  }
`
export const UploadBox = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.text};
  // border : 1px dashed ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  padding: 20px 25px;
  margin-top: 20px;
  position: relative;
  &:before {
    content: '';
    border-radius: 4px;
    border: 10px solid transparent;
    border-image: url(../assets/images/upload-field-border.svg) stretch 10;
    height: 95px;

    // background-image:
    background-size: 100% 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
    pointer-events: none;
  }
  // &:after {
  //   content:'';
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   border: 4px dashed #0e1619;
  //   border-radius: 4px;
  //   pointer-events: none;
  // }
  input {
    display: inline;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 25px 20px;
    .desktopText {
      display: none;
    }
    & > div:nth-child(3),
    & > div:nth-child(4) > div:first-child {
      display: none;
    }
    & > div:nth-child(4) {
      width: 80%;
      & > div:last-child {
        width: 100%;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    &:before {
      border-image: url(../assets/images/upload-field-mobile.svg) stretch 10;
    }
  }
`

export const ContinueButton = styled(Box) <{ width?: string, height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.text};
  // border : 1px solid ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.colors.buttonprimary};
  border-radius: 4px;
  font-weight: 600;
  font-size:16px;
  height: 44px;
  width: ${({ width }) => (width ? width : '100%')};
  cursor: pointer;
  border: none;
  a {
    background: none;
    border: none;
    color: #fff;
    width: 100%;
    padding: 0;
    height: 46px;
    text-align: center;
    padding: 11px 0;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    &.close-btn {
      max-width: 500px;
      width: 100%;
    }
  }
`
export const ContinueButtonClose = styled(Box) <{ width?: string, height?: string }>`
display: flex;
justify-content: center;
align-items: center;
font-family: ${({ theme }) => theme.fonts.secondary};
color: ${({ theme }) => theme.colors.text};
// border : 1px solid ${({ theme }) => theme.colors.secondary};
background: ${({ theme }) => theme.colors.buttonprimary};
border-radius: 4px;
font-weight: 600;
font-size:14px;
height: 56px;
width: ${({ width }) => (width ? width : '100%')};
cursor: pointer;
border: none;
a {
  background: none;
  border: none;
  color: #fff;
  width: 100%;
  padding: 0;
  height: 46px;
  text-align: center;
  padding: 11px 0;
}
${({ theme }) => theme.mediaQueries.sm} {
  &.close-btn {
    max-width: 500px;
    width: 100%;
  }
}
`


export const BackButtonBox = styled(Box) <{ width?: string, height?: string }>`
  height: ${({ height }) => (height ? height : '49px')};
  width: ${({ width }) => (width ? width : '100%')};
  background: none;
  padding: 1px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  cursor: pointer;
`
export const ButtonContainer = styled(Box)`
display: flex;
justify-content: flex-end;
padding: 0px 5px;
margin-top: -52px;
.min-class{
  width: 40px;
  background: linear-gradient(133.82deg,#513CFF 13.2%,#23ABD4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.max-class{
  width: 40px;
  font-weight: 550;
  background: linear-gradient(133.82deg,#513CFF 13.2%,#23ABD4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
`

export const MinMaxButton = styled(Box) <{ width?: string, height?: string }>`
display: flex;
justify-content: center;
align-items: center;
font-family: ${({ theme }) => theme.fonts.secondary};
border-radius: 4px;
font-weight: 500;
font-size:14px;
height: 56px;
width: ${({ width }) => (width ? width : '100%')};
cursor: pointer;
border: none;
${({ theme }) => theme.mediaQueries.sm} {
  &.close-btn {
    max-width: 500px;
    width: 100%;
  }
}
`

export const BackButton = styled(Box) <{ borderRadius?: string, isGradient?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  font-family: ${({ theme }) => theme.fonts.secondary};
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size:14px;
  position: relative;
  &:before {
    content: '';
    border: 1px solid transparent;
    background: linear-gradient(133.82deg, #513cff 13.2%, #23abd4 100%) border-box;
    -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) padding-box padding-box,
      linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
    -webkit-mask-composite: xor;
    -webkit-mask-composite: exclude;
    width: 100%;
    position: absolute;
    white-space: nowrap;
    inset: 0px;
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
    pointer-events: none;
  }
  .inner {
    
    background-image: ${({ theme, isGradient }) => (!isGradient && theme.colors.buttonprimary)};
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme, isGradient }) => (isGradient ? theme.colors.link : 'transparent')};
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }
`

export const SelectButtonBox = styled(Box)`
  height: 47px;
  width: 100%;
  background: none;
  padding: 1px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  cursor: pointer;
  & > div {
    background: none !important;
    width: 135px;
    height: 48px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    //background :url(../assets/images/btn-border.png) no-repeat center transparent;
    background-size: 100% 100%;
    font-family: ${({ theme }) => theme.fonts.secondary};
    border-radius: 4px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    &:before {
      content: '';
      border: 2px solid transparent;
      background: linear-gradient(135deg, rgb(30, 87, 153) 0%, rgb(35, 171, 212) 100%) border-box border-box;
      -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) padding-box padding-box,
        linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
      -webkit-mask-composite: xor;
      -webkit-mask-composite: exclude;
      width: 100%;
      position: absolute;
      inset: 0px;
      border-radius: 4px;
      pointer-events: none;
    }
    .inner {
      background-image: ${({ theme }) => theme.colors.buttonprimary};
      /* Use the text as a mask for the background. */
      /* This will show the gradient as a text color rather than element bg. */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }
`

export const MainRow = styled(Row)`
margin:0 !important;
  justify-content: space-between;
  .col-sm-px-0 {
    padding:0 !important;
  }
`

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.failure};
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.light};
  margin: 0 0 0 0.2rem;
`

export const MobileDialog = styled(Box)`
  z-index: 11;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 100vw;
    height: 100vh;
    z-index: 11;
    img {
      display: none;
    }
    .modal-overflow {
      width:78%;
      ${({ theme }) => theme.mediaQueries.md} {
        width:88%;
      }
      ${({ theme }) => theme.mediaQueries.sm} {
        width:90%;
      }
      max-width: 1600px;
      & > div {
        height: 100vh;
        display: table;
        width: 100%;
        background-image: url(../assets/images/backgroundGradient.png);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 500px;
        & > div:first-child {
          display: none;
        }
        & > div:last-child {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    .dialog-btns {
      max-width: 90vw;
      & > div:first-child {
        margin-right: 10px;
      }
      & > div:last-child {
        margin-left: 10px;
      }
    }
  }
`
